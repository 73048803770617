<script setup lang="ts">
import SectionHeader from './SectionHeader.vue';
import DropdownContent from './DropdownContent.vue';
import { ref, watchEffect } from 'vue';

const headerLoaded = ref<boolean>(false);
const img1Loaded = ref<boolean>(false);
const img2Loaded = ref<boolean>(false);
const img3Loaded = ref<boolean>(false);

const emit = defineEmits(['loaded']);

watchEffect(() => {
    if (headerLoaded.value && img1Loaded.value && img2Loaded.value && img3Loaded.value) {
        emit('loaded');
    }
});
</script>
<template>
    <div>
        <SectionHeader id="myWork" text="Wie ich energetisch arbeite" @loaded="headerLoaded = true" />
        <div class="container">
            <div class="text">
                <p>
                    Ich arbeite mit den vier Energieebenen – der spirituellen, der mentalen, der emotionalen und der
                    körperlichen Ebene. Viele richten ihren Fokus lediglich auf den Körper, vor allem wenn Symptome
                    auftreten, allerdings herrscht zwischen den Ebenen ein tieferer Zusammenhang. Denn erst wenn im
                    mentalen und emotionalen Bereich eine Disharmonie herrscht, äußert sich diese auch körperlich. Die
                    Kraft der Gedanken hat großen Einfluss auf unsere Gefühlswelt und letztendlich Auswirkung auf die
                    physische Ebene.</p>
                <p>Meine Aufgabe ist es, dabei zu unterstützen diese Ebenen wieder in Einklang zu bringen. Dabei werden
                    Blockaden gelöst, so dass die Energie wieder frei fließen kann, mehr Balance entsteht und die
                    Selbstheilungskräfte aktiviert werden.
                </p>
            </div>
            <div class="image">
                <div class="imageRelativeContainer">
                    <img src="../assets/meine_arbeit1.jpg"
                        alt="Eine Person mit einem T-Shirt liegt auf einem Tisch und wird aktuell behandelt."
                        @load="img1Loaded = true" />
                    <div class="blob image1" />
                    <div class="blob image2" />
                    <div class="blob image3" />
                </div>
            </div>
        </div>
        <div class="container reverse">
            <div class="image wider">
                <div class="imageRelativeContainer">
                    <img src="../assets/meine_arbeit2.jpg"
                        alt="Ein Kind sitzt auf einem Therapietisch mit dem Rücken zur Kamera. Doris Böck hält die Füße des Kindes und lacht."
                        @load="img2Loaded = true" />
                    <div class="blob image4" />
                    <div class="blob image5" />
                </div>
            </div>
            <div class="text">
                <p>
                    Besonders empfänglich für Energiearbeit sind Kinder. Sie nehmen Energien viel intensiver wahr, als
                    Erwachsene sich das vorstellen können. Das kann emotionalen Stress bei den Kindern erzeugen. Solche
                    Kinder haben oft Schwierigkeiten sich an neue Situationen zu gewöhnen und soziale Kontakte zu
                    knüpfen, agieren mit Aggressivität oder sind sehr verschlossen. Es ist mir ein großes Anliegen
                    Kinder, die ich schon in der Pflege gern betreut habe, als Energetikerin ein Stück ihres Weges
                    begleiten zu dürfen. Ich wünsche mir für jedes Kind eine unbeschwerte Kindheit.
                </p>
            </div>
        </div>
        <div class="container">
            <div class="text">
                <p class="boldText">Ausbildungen und Qualifikationen</p>
                <ul class="educationList">
                    <li>Diplom für Kinder und Jugendlichenpflege</li>
                    <li>Diplom für Mentaltraining nach Renate Plank</li>
                    <li>Diplom für Humanenergetik</li>
                    <li>Energie- und Bewusstseinsarbeit nach Heiko Wenig</li>
                    <li>Energie der Sprache nach Heiko Wenig</li>
                    <li>Energiecoach nach Heiko Wenig</li>
                    <li>Erdheilung und Raumreinigung nach Heiko Wenig</li>
                    <li>Körperorientierte Energiearbeit nach Dagmar Pauer und Heiko Wenig</li>
                    <li>Geistige Wirbelsäulenaufrichtung</li>
                    <li>Heilsame Berührungen nach Barbara Heider-Rauter, Kurs I</li>
                    <li>Energetischer Beziehungscoach nach Heiko Wenig</li>
                </ul>
            </div>
            <div class="image education">
                <div class="imageRelativeContainer">
                    <img src="../assets/meine_arbeit3.jpg"
                        alt="Auf einem Fenster sind Sterne in brauner Farbe aufgemalt." @load="img3Loaded = true" />
                    <div class="blob education image6" />
                    <div class="blob education image7" />
                </div>
            </div>
            <div class="blob education image8" />
        </div>
        <div class="dropdownContainer">
            <DropdownContent>
                <template #heading>
                    <span>Energiecoaching</span>
                </template>
                <template #content>
                    <p>Ein Energiecoaching unterstützt bei Neuorientierung und Selbstfindung und bezieht neben
                        körperlicher, emotionaler und mentaler Entwicklung auch die spirituelle Welt mit ein. Es geht
                        hierbei um Bewusstseinsentwicklung zum höchsten Wohl. Motivation, Freude und Begeisterung für
                        eine Neuausrichtung sind nur der Anfang für mehr Erfüllung im Leben, sei es privat und/oder
                        beruflich.</p>
                </template>
            </DropdownContent>
            <DropdownContent>
                <template #heading>
                    <span>Körperorientierte Energiearbeit</span>
                </template>
                <template #content>
                    <p>Bei der körperorientierten Energiearbeit liegt der Fokus auf den Organen, dem Hormon- und
                        Nervensystem, den verschiedenen Kreisläufen, Knochen, Muskeln, Bänder, Wirbelsäule,
                        Fehlstellungen uvm. Grundstrukturen des Körpers werden dabei wieder in das Systemnetz der
                        göttlichen Ordnung angebunden.</p>
                </template>
            </DropdownContent>
            <DropdownContent>
                <template #heading>
                    <span>Erdheilung und Raumreinigung</span>
                </template>
                <template #content>
                    <p>Jeder Raum und jeder Platz hat eigene Schwingungen und Energien, welche ebenfalls auf unser
                        Energiefeld wirken und somit auch auf unser Leben. Wohlbefinden, Gesundheit, Schlafqualität,
                        Partnerschaft, Familienleben, Arbeitsklima, die Entwicklung unseres Geistes und Bewusstseins
                        werden stark von dieser Energie beeinflusst. Eine Verbesserung der Erd-, Raum- und Platzenergie
                        führt dadurch auch zu einer erheblichen Verbesserung all dieser Bereiche, sowie der gesamten
                        Lebensqualität. Diese können durch die Techniken der Erdheilung und Raumreinigung erzielt
                        werden. </p>
                </template>
            </DropdownContent>
        </div>
    </div>
</template>
<style scoped>
.dropdownContainer {
    padding-left: 56px;
    padding-right: 56px;
    padding-top: 16px;
    padding-bottom: 16px;
    position: relative;
    max-width: 1350px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18px;
}

.blob {
    background-color: #d7ebe1;
}

.blob.education {
    background-color: #e1dbd2;
}

.image.wider {
    width: 400px;
    align-content: center;
}

.image.wider img {
    max-width: 400px;
}

.image.education {
    margin-left: auto;
}

.educationList li {
    padding-top: 10px;
}

@media screen and (max-width: 850px) {
    .container.reverse {
        display: flex;
        flex-direction: column-reverse;
    }

    .image.wider {
        width: 100% !important;
        text-align: center;
        margin-top: 18px;
    }

    .image.wider img {
        width: 100% !important;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
    }

    .blob.image1 {
        display: none;
    }

    .blob.image4 {
        top: -30px !important;
    }

    .blob.image6 {
        top: -20px !important;
    }
}

.blob.image1 {
    top: -80px;
    right: 0;
}

.blob.image2 {
    top: 20px;
    left: -40px;
}

.blob.image3 {
    bottom: -15px;
    right: -60px;
}

.blob.image4 {
    top: -60px;
    left: 20px;
}

.blob.image5 {
    bottom: -20px;
    right: -10px;
}

.blob.image6 {
    top: -60px;
    left: 10px;
}

.blob.image7 {
    bottom: -30px;
    left: -15px;
}

.blob.image8 {
    right: -70px;
    top: 50px;
}

.boldText {
    font-weight: bold;
}
</style>